<template>
  <div class="page">
    <div class="title">发放工资条</div>

    <el-divider></el-divider>

    <!-- table -->
    <el-table
      id="table"
      :data="tableData"
      :header-cell-style="tableHeaderStyle"
      :cell-style="tableCellStyle"
      style="width: 100%;"
    >
      <el-table-column prop="name" label="工资表名称"></el-table-column>
      <el-table-column prop="type" label="所属月份"></el-table-column>
      <el-table-column prop="company" label="总人数"></el-table-column>
      <el-table-column prop="post" label="已发放"></el-table-column>
      <el-table-column prop="base_pay" label="操作人"></el-table-column>
      <el-table-column prop="net_pay" label="操作时间"></el-table-column>
      <el-table-column label="操作" min-width="180" fixed="right">
        <div class="operations">
          <div>查看发放详情</div>
          <div>继续发放</div>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        keyword: "",
      },
      tableData: [],

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.operations {
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    font-size: 14px;
    color: #406bff;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 13px;
    }
  }
}
</style>
